/* src/components/About.css */

.about-section {
    padding: 3rem 1.5rem;
    background-color: #f4f8fb;
    color: #333;
    text-align: center;
  }
  
  .about-section h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    gap: 2rem;
  }
  
  .about-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .about-details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
  
  .detail-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-align: left;
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .detail-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
  }
  
  .icon {
    font-size: 1.8rem;
    color: #4a90e2;
  }
  
  .detail-item h4 {
    font-size: 1.2rem;
    margin: 0;
    color: #333;
    font-weight: 500;
  }
  
  .detail-item p {
    margin: 0;
    color: #666;
    font-weight: 300;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .about-content {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .about-description {
      flex: 1;
      max-width: 400px;
      text-align: left;
    }
  
    .about-details {
      flex: 1;
      align-items: flex-start;
    }
  }
  