/* src/pages/Home.css */

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(135deg, #f0f4ff, #d1e0ff);
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.header,
.about-section,
.main-content {
  width: 100%; /* Ensure full width for each major section */
  padding: 2rem 1rem; /* Padding to add space on mobile */
  box-sizing: border-box;
}

.main-content {
  display: flex;
  gap: 2rem;
  max-width: 90%; /* Center content and keep it within a max width */
  width: 100%;
}

.left-column, .right-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.left-column {
  flex: 2; /* Left column takes 2/3 of space */
}

.right-column {
  flex: 1; /* Right column takes 1/3 of space */
}

/* Section styling */
.main-content > .left-column > div,
.main-content > .right-column > div {
  background: #fff;
  border-radius: 15px;
  /* padding: 1.5rem; */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

/* Hover effect */
.main-content > .left-column > div:hover,
.main-content > .right-column > div:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);
}

/* Responsive stacking for mobile */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 1rem;
    padding: 0rem;
  }

  .left-column, .right-column {
    flex-basis: 100%;
  }

  .main-content > .left-column > div,
  .main-content > .right-column > div {
    padding: 0rem;
  }
}
