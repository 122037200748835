/* src/components/Projects.css */

.projects-section {
    padding: 3rem 1.5rem;
    background-color: transparent;
    color: #333;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    text-align: center;
  }
  
  .projects-section h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  
  .projects-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .project-card {
    background: #fff;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    text-align: left;
    position: relative;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);
  }
  
  .project-card h3 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #4a90e2;
    margin-bottom: 1rem;
  }
  
  .project-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  .project-description li {
    list-style-type: disc;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .project-links {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  
  .project-link {
    color: #ffffff;
    background-color: #4a90e2;
    border-radius: 20px;
    padding: 0.5rem 1.2rem;
    font-size: 0.9rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .project-link:hover {
    background-color: #367bb5;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .projects-section {
      padding: 1rem 0.5rem;
    }
  
    .project-card {
      padding: 1.5rem;
    }
  
    .project-card h3 {
      font-size: 1.4rem;
    }
  
    .project-description {
      font-size: 0.95rem;
    }
  
    .project-link {
      font-size: 0.85rem;
      padding: 0.4rem 1rem;
    }
  }
  