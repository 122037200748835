/* src/components/Education.css */

.education-section {
    padding: 3rem 1.5rem;
    background-color: transparent;
    color: #333;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    text-align: center;
  }
  
  .education-section h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  
  .education-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .education-card {
    background: #fff;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    position: relative;
  }
  
  .education-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);
  }
  
  .education-card h3 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #4a90e2;
    margin-bottom: 0.5rem;
  }
  
  .education-card h4 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .education-location, .education-dates {
    font-size: 0.95rem;
    color: #888;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.25rem 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .education-section {
      padding: 1.5rem;
    }
  
    .education-card {
      padding: 1.5rem;
    }
  
    .education-card h3 {
      font-size: 1.4rem;
    }
  
    .education-card h4 {
      font-size: 1.1rem;
    }
  
    .education-location, .education-dates {
      font-size: 0.9rem;
    }
  }
  