/* Header Styles */
.header {
  width: 100vw;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

/* Desktop Navigation Container - Left and Right Alignment */
.desktop-nav-container {
  position: absolute;
  top: 20px; /* Space from the top */
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between; /* Align left and right sections */
  padding: 0 20px;
  align-items: center;
}

.desktop-nav-left {
  display: flex;
  gap: 20px;
}

/* Navigation Links */
.nav-link {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.2rem !important;
  position: relative;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ffba08;
}

/* Download Resume Button */
.btn-download {
  padding: 8px 16px;
  background-color: #ffba08;
  color: #1a1a1a;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn-download:hover {
  background-color: #ffa500;
  box-shadow: 0 4px 12px rgba(255, 186, 8, 0.3);
}

/* Profile Section */
.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px; /* Space below the navigation pane */
}

.profile-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  margin-bottom: 10px;
}

.role {
  font-size: 1.2rem;
  color: #ddd;
  margin-bottom: 10px;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-links a {
  font-size: 1.5rem;
  color: #ffffff;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
  color: #ffba08;
  transform: translateY(-4px);
}

/* Mobile Specific Styles */
.mobile-nav-toggle {
  display: none;
}

@media (max-width: 768px) {
  .desktop-nav-container {
    display: none;
  }

  .mobile-nav-toggle {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 20px;
  }

  .menu-icon {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    text-align: center;
  }

  .mobile-nav .nav-link, .mobile-nav .btn-download {
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
  }
}


.profile {
  width: 100%;
  /* height: 600px; */
}



/* .glitch{
  font-family: "Rubik Glitch", system-ui;
  font-weight: 400;
  font-style: normal;
} */

.russo-one-regular {
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

.glitch:nth-child(2) {
  color: #67f3da;
  animation: glitch2 2.5s infinite;
}

.glitch:nth-child(3) {
  color: #f16f6f;
  animation: glitch3 2.5s infinite;
}
/*Keyframes*/

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }
  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  27% {
    transform: none;
    opacity: 1;
  }
  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    transform: none;
    opacity: 1;
  }
  52% {
    transform: none;
    opacity: 1;
  }
  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }
  50% {
    transform: none;
    opacity: 1;
  }
  72% {
    transform: none;
    opacity: 1;
  }
  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }
  80% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}

@keyframes glitch3 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}



.stroked-text {
  text-decoration: line-through;
  text-decoration-color: black;
}

.role {
  font-size: 2rem;
  color: #ddd;
  margin-bottom: 10px;
  font-family: "Audiowide", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 20px;
}


.profile-image {
  /* margin:auto; */
  margin-top: 100px;
  /* margin-left: -100px; */
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border:10px solid #fff;
  margin-bottom: 10px;
}

.pink {
  color: magenta;
}

@media (max-width: 991.98px) {
  

  .profile-image {
    /* margin:auto; */
    margin-top: 0;
    /* margin-left: -100px; */
    width: 350px;
    height: 350px;
    border-radius: 50%;
    border:10px solid #fff;
    margin-bottom: 10px;
  }

  .introduction {
    margin: auto;
    margin-top: 30px;
    font-size: 2.5rem;
  }

  .glitch {
    text-align: center;
    color: cyan;
    font-style: italic;
    font-size: clamp(1rem, 10vw, 5rem);
    letter-spacing: 8px;
    /* font-family: 'Press Start 2P'; */
    font-weight: 400;
    /*Create overlap*/
    
    margin: 0;
    line-height: 0;
    /*Animation*/
    
    animation: glitch1 2.5s infinite;
  }

}

@media (max-width: 768px) {

  .profile-image {
    /* margin:auto; */
    margin-top: 0;
    /* margin-left: -100px; */
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border:10px solid #fff;
    margin-bottom: 10px;
  }

}

/* @media (min-width: 768px)  and (max-width: 991.98px){
  .pink {
    color: blue;
  }

  .introduction {
    margin: auto;
    margin-top: 30px;
    font-size: 3rem;
  }

  .part-1 {

    display: grid;
    place-items: center;
    height: 100%;

    
  }
  
} */

@media (min-width: 992px) {

  .glitch {
    text-align: center;
    color: cyan;
    font-style: italic;
    font-size: 5rem;
    letter-spacing: 8px;
    /* font-family: 'Press Start 2P'; */
    font-weight: 400;
    /*Create overlap*/
    
    margin: 0;
    line-height: 0;
    /*Animation*/
    
    animation: glitch1 2.5s infinite;
  }


  .introduction {
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
  }
}