/* Contact.css */

.contact-container {
  padding: 2rem;
}

.contact-content {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

/* Reset margins and padding for Bootstrap columns */
/* .contact-image,
.contact-form {
  padding: 0;
} */

label {
  color: black;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

/* Contact image and info box */
.contact-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-info {
  position: absolute;
  bottom: 0;
  background-color: #002b5b;
  color: white;
  padding: 1rem;
  width: 100%;
  text-align: center;
}

.contact-info h5 {
  margin-bottom: 0.5rem;
}

.contact-info p {
  margin: 0.2rem 0;
  font-size: 0.9rem;
}

/* Contact form styling */
.contact-form {
  padding: 2rem !important;
  background-color: #f8f8f8;
}

.contact-form h2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #333;
  font-family: 'Poppins',Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

/* Form-group styles with custom padding adjustments */
.contact-form .form-group {
  margin-bottom: 1rem;
}

/* .form-control {
    height: 50px;
	background: white;
	color: rgba(0,0,0,.8);
	font-size: 14px;
	border-radius: 5px;
	border: none;
	-webkit-box-shadow: 0px 8px 19px -13px rgba(0,0,0,0.09) !important;
	-moz-box-shadow: 0px 8px 19px -13px rgba(0,0,0,0.09) !important;
	box-shadow: 0px 8px 19px -13px rgba(0,0,0,0.09) !important;
} */

.contact-form .form-group.row .col-md-6:first-child {
    padding-right: 1rem;
}
.contact-form .form-group.row .col-md-6:last-child {
    padding-left: 1rem;
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px !important;
  overflow-y: auto;
}

/* Button styling */
.btn-success {
  background-color: #28a745 !important;
  color: white;
  font-size: 1rem;
}

.btn-success:hover {
  background-color: #218838 !important;
}

/* Status message styling */
.status-message {
  margin-top: 1rem;
  padding: 0.75rem;
  text-align: center;
  border-radius: 4px;
}

.status-message.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.status-message.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}
