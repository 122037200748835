.skills-section {
  padding: 3rem 1.5rem;
  background-color: transparent;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  text-align: center;
}

.skills-section h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  font-weight: bold;
}

.skills-container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

.skills-category {
  margin-bottom: 1.5rem;
}

.skills-category h3 {
  font-size: 1.4rem;
  color: #4a90e2;
  font-weight: 600;
  margin-bottom: 1rem;
  border-bottom: 2px solid #d1e0ff;
  padding-bottom: 0.5rem;
}

.skills-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.skill-chip {
  background-color: #fdfdfd;
  color: #333;
  border-radius: 20px;
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: default;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.skill-chip:hover {
  background-color: #d1e0ff;
  transform: translateY(-2px);
}

/* Icon styling */
.skill-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
}

/* Skill name styling */
.skill-name {
  margin-left: 0.3rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  .skills-section {
    padding: 1rem 0.5rem;
  }

  .skills-container {
    padding: 1rem 0.5rem;
  }

  .skills-category h3 {
    font-size: 1.2rem;
  }

  .skill-chip {
    font-size: 0.85rem;
    padding: 0.3rem 0.7rem;
  }
}
